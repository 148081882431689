import { useEffect, useState } from "react";

export interface ViewportDimensions {
    width: number,
    height: number
}

export function getViewportDimensions(): ViewportDimensions {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}

export function useViewportDimensions(): ViewportDimensions {
    const [viewportDimensions, setViewportDimensions] = useState(getViewportDimensions());
    useEffect(() => {
        function handleResize() {
            setViewportDimensions(getViewportDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return viewportDimensions;
}
