import builtinAccept from "../assets/sounds/accept.mp3";
import builtinAttention from "../assets/sounds/attention.mp3";
import builtinBlip from "../assets/sounds/blip.mp3";
import builtinMilestone from "../assets/sounds/milestone.mp3";
import builtinReject from "../assets/sounds/reject.mp3";
import builtinUIClose from "../assets/sounds/uiclose.mp3";
import builtinUIOpen from "../assets/sounds/uiopen.mp3";

let mainAudioContext: AudioContext;
let masterCompressor: DynamicsCompressorNode;
let sfxGain: GainNode;
let musicGain: GainNode;
let musicFilter: BiquadFilterNode;

let musicSource: AudioNode;

const preloadedSounds: { [key: string]: AudioBuffer } = {}

export enum Sound {
    UIOpen,
    UIClose,
    Attention,
    Accept,
    Reject,
    Blip,
    Milestone,
}

const builtinSounds: {[key in Sound]: string} = {
    [Sound.UIOpen]: builtinUIOpen,
    [Sound.UIClose]: builtinUIClose,
    [Sound.Attention]: builtinAttention,
    [Sound.Accept]: builtinAccept,
    [Sound.Reject]: builtinReject,
    [Sound.Blip]: builtinBlip,
    [Sound.Milestone]: builtinMilestone,
};

export function playSound(sound: Sound) {
    const url = builtinSounds[sound];

    const preloaded: AudioBuffer | undefined = preloadedSounds[url];
    if (!preloaded) {
        return;
    }

    mainAudioContext.resume().then(() => {
        const sourceNode = new AudioBufferSourceNode(mainAudioContext, {buffer: preloaded});
        sourceNode.connect(sfxGain);
        sourceNode.start();
    })
    .catch((error) => {
        console.error(`Error in audio playback: ${error}`);
    });
}

export function diluteMusic() {
    musicFilter.frequency.cancelScheduledValues(mainAudioContext.currentTime);
    musicFilter.frequency.linearRampToValueAtTime(300, mainAudioContext.currentTime + 0.2);
}

export function undiluteMusic() {
    //musicFilter.frequency.cancelScheduledValues(mainAudioContext.currentTime);
    musicFilter.frequency.linearRampToValueAtTime(musicFilter.frequency.maxValue, mainAudioContext.currentTime + 1);
}

export function initialiseMusic(audioElement: HTMLAudioElement) {
    if (musicSource !== undefined) {
        return;
    }

    musicSource = mainAudioContext.createMediaElementSource(audioElement);
    musicSource.connect(musicFilter);
}

export async function initialiseAudio() {
    if (mainAudioContext !== undefined) {
        return;
    }

    mainAudioContext = new AudioContext();

    sfxGain = mainAudioContext.createGain();
    musicGain = mainAudioContext.createGain();
    musicFilter = mainAudioContext.createBiquadFilter();
    masterCompressor = mainAudioContext.createDynamicsCompressor();

    sfxGain.gain.setValueAtTime(0.5, 0);
    musicGain.gain.setValueAtTime(0.5, 0);

    musicFilter.connect(musicGain);
    musicFilter.type = "lowpass";
    musicFilter.frequency.value = musicFilter.frequency.maxValue;
    sfxGain.connect(masterCompressor);
    musicGain.connect(masterCompressor);

    masterCompressor.attack.setValueAtTime(1.0, 0);
    masterCompressor.release.setValueAtTime(5.0, 0);
    masterCompressor.connect(mainAudioContext.destination);

    for (const sound of Object.values(builtinSounds)) {
        if (!(sound in preloadedSounds)) {
            const response = await fetch(sound);
            const arrayBuffer = await response.arrayBuffer();
            const audioBuffer = await mainAudioContext.decodeAudioData(arrayBuffer);
            preloadedSounds[sound] = audioBuffer;
        }
    }
}
