import { Button } from "../Button";
import { useContext } from "react"
import { EngineContext } from "../../contexts/EngineContext"
import './Research.css';
import { ProgressBar } from "../ProgressBar";

export interface ResearchProps {
};

export function Research(_props: ResearchProps) {
    const [engine, gameState] = useContext(EngineContext);

    const isResearching = gameState && gameState.player.action == "Researching";

    const researchOnClick = () => {
        if (isResearching) {
            engine?.setPlayerAction("None");
        }
        else {
            engine?.setPlayerAction("Researching");
        }
    }

    const progress = gameState ?
        (gameState.player.research_available % 1000) / 1000 :
        0;

    return <div className="Research">
        <Button title={isResearching ? "Stop" : "Start" } onClick={researchOnClick} />
        <div>
            Currently researching? {isResearching ? "Yes" : "No"}
        </div>
        <ProgressBar width={300} completion={progress} />
        <div>
            Current research: {gameState && Math.floor(gameState.player.research_available / 1000)}
        </div>
    </div>
}
