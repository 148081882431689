import { Subject, Subscription } from "rxjs";
import { playSound, diluteMusic, undiluteMusic, Sound } from "./AudioService";
import { DialogProps, DialogState } from "../components/Dialog";
import { useState, useEffect } from "react";

export class DialogService {
    static subscribers: Subject<DialogState> = new Subject();

    static display(state: DialogProps, sound?: Sound) {
        if (sound) {
            playSound(sound);
        }
        diluteMusic();
        this.subscribers.next({visible: true,
            message: state.message,
            buttons: state.buttons.reverse().map(button => {
                return {
                    ...button,
                    onClick: () => {
                        this.dismiss();
                        if (button.onClick)
                            button.onClick();
                    }
                }
            })
        });
    }

    private static dismiss() {
        undiluteMusic();
        this.subscribers.next({visible: false, message: "", buttons: []});
    }

    static subscribe(observer: ((state: DialogState) => void)): Subscription {
        return this.subscribers.subscribe(observer);
    }
}

export function useDialogService() {
    const [dialogState, setDialogState] = useState<DialogState>({visible: false, message: "", buttons: []});
    useEffect(() => {
        const subscription = DialogService.subscribe(setDialogState);
        return () => {
            subscription.unsubscribe();
        }
    }, [setDialogState]);

    return dialogState;
}
