import { useState, useEffect } from "react";
import React from 'react';

export type Engine = typeof import("../engine/rudis_materiae_engine");

export const EngineContext = React.createContext<[Engine | undefined, any]>([undefined, undefined]);

export function useEngine() {
    const [engine, setEngine] = useState<Engine | undefined>(undefined);
    const [gameState, setGameState] = useState<any>(undefined);
    useEffect(() => {
        async function importEngine() {
            const importedEngine = await import("../engine/rudis_materiae_engine");
            await importedEngine.default();
            importedEngine.initialiseEngine(undefined);
            setEngine(importedEngine);
        }

        if (engine === undefined) {
            importEngine();
        }
    }, [engine]);

    return [engine, gameState, setGameState];
}
