import { MouseEvent, PointerEvent } from "react";
import { Sound, playSound } from "../services/AudioService";
import "./Button.css";

export interface ButtonProps<T> {
    onClick?: ((e: MouseEvent, value?: T) => void),
    onPointerEnter?: ((e: PointerEvent) => void),
    sound?: Sound | null,
    title: String
    x?: number,
    y?: number,
    width?: number
    value?: T
}

export function Button<T>(props: ButtonProps<T>) {
    const onPointerEnter = (e: PointerEvent): void => {
        if (e.pointerType === "mouse") {
            playSound(Sound.Blip);
        }
        if (props.onPointerEnter) {
            
            props.onPointerEnter(e);
        }
    }

    const onClick = (e: MouseEvent): void => {
        if (props.sound) {
            playSound(props.sound);
        }
        if (props.onClick) {
            props.onClick(e, props.value);
        }
    }

    return <svg height="50" width={props.width || 130} x={props.x || 0} y={props.y || 0} onPointerEnter={onPointerEnter} className="Button" onClick={onClick}>
            <g transform="translate(5, 5)">
                <g className="Button-base">
                    <path d={`M10,40 h-10 v-30 l10 -10 h${(props.width || 130) - 20} v30 l-10 10 Z`}/>
                    <g transform="translate(60, 28)">
                        <text className="Button-text">{props.title}</text>
                    </g>
                </g>
            </g>
        </svg>;
}
