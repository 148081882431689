import React, { useState } from "react";
import { useViewportDimensions } from "../services/ViewportService";
import "./SideMenu.css";

export interface SideMenuProps {
    opened?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    children?: React.ReactElement[];
};

export function SideMenu(props: SideMenuProps) {
    const [animating, setAnimating] = useState(false);
    const viewportDimensions = useViewportDimensions();

    const toggleOpen = () => {
        if (animating) {
            return;
        }
        if (props.opened) {
            if (props.onClose)
                props.onClose()
        }
        else {
            if (props.onOpen)
                props.onOpen();
        }
    };

    const classNames = [
        "SideMenu",
        props.opened === undefined ? "SideMenu-initial" :
        props.opened === true ? "SideMenu-open" :
        "SideMenu-closed"
    ];

    return <div className={classNames.join(" ")} onAnimationStart={() => setAnimating(true)} onAnimationEnd={() => setAnimating(false)}>
        <svg height={viewportDimensions.height} width={300} className="SideMenu-bg">
            <rect x="0" y="0" width="310" height="100%" className="SideMenu-bg-main" />
            <g className="SideMenu-bg-grip" onClick={toggleOpen}>
                <rect x="260" y="0" width="40" height="100%" fill="#ffffff00" />
                <line x1="295" y1="5" x2="295" y2={viewportDimensions.height - 5} className="SideMenu-bg-grip-line" />
                <line x1="290" y1="5" x2="290" y2={viewportDimensions.height - 5} className="SideMenu-bg-grip-line" />
                <line x1="285" y1="5" x2="285" y2={viewportDimensions.height - 5} className="SideMenu-bg-grip-line" />
                <g transform={`translate(290,${viewportDimensions.height / 2})`}>
                    <g transform="translate(-10,-20)">
                        <path d="M2,2 l14,14 l-14,14" className="SideMenu-bg-indicator-under" />
                        <path d="M5,5 l10,10 l-10,10" className="SideMenu-bg-indicator" />
                    </g>
                </g>
            </g>
        </svg>
        {props.children}
    </div>
}
