import './ProgressBar.css';

export interface ProgressBarProps {
    animated?: boolean,
    completion: number,
    width?: number,
    height?: number,
}

export function ProgressBar(props: ProgressBarProps) {
    const clamped = Math.max(0, Math.min(1, props.completion));

    const width = props.width || 200;
    const height = props.height || 60;

    const innerWidth = width - 10;
    const innerHeight = height - 10;

    const animated = props.animated !== false && props.completion > 0;

    return <svg className="ProgressBar" width={width} height={height}>
        <g transform="translate(5,5)">
            <rect x={0} y={0} width={innerWidth-5} height={innerHeight-5} className="ProgressBar-background" />
            <rect x={0} y={0} width={(innerWidth-5) * props.completion} height={innerHeight-5} className={`ProgressBar-indicator ${animated ? "ProgressBar-animated" : ""}`} />
        </g>
        <g transform="translate(2,2)">
            <path className="ProgressBar-shell" d={`M0,4 l4,-4 h${innerWidth-4} l4,4 v${innerHeight-4} l-4,4 H4 l-4,-4 z M10,30 l20,-20 h${innerWidth-36} v${innerHeight-36} l-20,20 H10 z`} />
        </g>
    </svg>
}
