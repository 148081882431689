import React from "react";
import "./Window.css";

interface WindowProps {
    simple?: boolean,
    x?: number | string,
    y?: number | string,
    transform?: string,
    width: number,
    height: number,
    children: React.ReactNode
}

export function Window(props: WindowProps) {
    const outerPath = `
        M10,10
            h${props.width - 40}
            l10,-10
            h20
            l30,30
            v30
            l-10,10
            V${props.height}
            l-10,10
            H110
            l-20,20
            h-90
            l-20,-20
            v-90
            l20,-20
            V20
        z` as const;
    
    const innerPath = `
        M16,20
            H${props.width - 110}
            l20,20
            h80
            l20,20
            V${props.height - 70}
            l-20,20
            v30
            l-20,20
            H20
            l-10,-10
            V26
        z` as const;

    return <svg width={props.width + 80} height={props.height + 80} x={props.x} y={props.y} transform={props.transform}>
        <g transform="translate(40, 10)">
            <path d={outerPath} className="WindowBorder-shadow" />
        </g>
        <g transform="translate(40, 10)">
            {props.children}
        </g>
        <g transform="translate(30, 0)">
            <path fillRule="evenodd" d={`${outerPath} ${innerPath}`} className="WindowBorder-frame"/>
            <path d={outerPath} className="WindowBorder-frame-outerBorder"/>
            <path d={innerPath} className="WindowBorder-frame-innerBorder"/>
            <path d={`M-10,${props.height - 70} l10,-10 m-10,18 l10,-10 m-10,18 l10,-10 m-10,18 l10,-10 m-10,18 l10,-10`} className="WindowBorder-embellishments" />
            <circle cx="60" cy={props.height + 15} r="5" className="WindowBorder-embellishment-led1" />
            <circle cx="80" cy={props.height + 15} r="5" className="WindowBorder-embellishment-led2" />
            <path d={`M${props.width - 96},20 h66 m-60,6 h66 m-60,6 h66`} className="WindowBorder-embellishments" />
        </g>
    </svg>
}
