import "./Logo.css";

export function Logo() {
    return <svg width={260} height={70} className="Logo">
        <g transform="translate(130, 35)">
            <text className="Logo-text">Rudis Materiae</text>
        </g>
        <g transform="translate(0, 50)">
            <line x1={20} y1={0} x2={120} y2={0} className="Logo-underline" />
            <line x1={140} y1={0} x2={240} y2={0} className="Logo-underline" />
            <path d="M125,0 l5,-5 l5,5 l-5,5 z" className="Logo-underline-diamond" />
        </g>
    </svg>
}
