import { useEffect, useState } from 'react';
import { EngineContext, useEngine } from './contexts/EngineContext';
import { initialiseAudio, playSound, Sound } from './services/AudioService';
import { DialogService, useDialogService } from './services/DialogService';
import { Blank } from './components/Blank';
import { Dialog } from './components/Dialog';
import { GameViewIcons, GameViewTitles } from './data/GameViewData';
import { Logo } from './components/Logo';
import { MusicController } from './components/MusicController';
import { SideMenu } from './components/SideMenu';
import { ActivityList, ListItem } from './components/ActivityList';
import { GameView } from './types/GameView';

import './App.css';
import { ViewSwitcher } from './components/gameviews/ViewSwitcher';

const supportedGameViews = [
  GameView.Exploration,
  GameView.Extraction,
  GameView.Smelting,
  GameView.Assembly,
  GameView.Research,
  GameView.Statistics,
  GameView.Editor,
];

function App() {
  const [engine, gameState, setGameState] = useEngine();
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean | null>(null);
  const [gameView, setGameView] = useState(GameView.Blank);
  const dialogState = useDialogService();

  useEffect(() => {
    async function init() {
      try {
        await initialiseAudio();
        
        setLoaded(true);
      }
      catch (error) {
        console.error(error);
        setLoaded(false);
      }
    };

    if (loaded === null) {
      init();
    }
  }, [loaded]);

  useEffect(() => {
    if (engine !== undefined) {
      let timer = setInterval(() => {
        const notifications: any[] = engine.tick();
        notifications.forEach((notification) => {
          if (notification.severity == "Milestone") {
            DialogService.display({
              message: `Milestone unlocked: ${notification.message_id}`,
              buttons: [
                { title: "OK" }
              ]
            }, Sound.Milestone);
          }
        });
        setGameState(engine.getState());
      }, 100);

      return () => {
        clearInterval(timer);
      };
    }
  }, [engine, setGameState]);

  const onSideMenuOpen = () => {
    playSound(Sound.UIOpen);
    setSideMenuOpen(true);
  }

  const onSideMenuClose = () => {
    playSound(Sound.UIClose);
    setSideMenuOpen(false);
  }

  const activityClick = (gameView: GameView) => {
    playSound(Sound.Accept);
    onSideMenuClose();
    setGameView(gameView);
  }

  if (loaded === null || engine === undefined) {
    return <div>Loading...</div>;
  }
  else if (loaded === false) {
    return <div>Loading... failed!</div>;
  }

  return <EngineContext.Provider value={[engine, gameState]}>
    <SideMenu opened={sideMenuOpen} onOpen={onSideMenuOpen} onClose={onSideMenuClose}>
      <Logo />
      <ActivityList>
        {supportedGameViews.map(gv => <ListItem key={gv} value={gv} icon={GameViewIcons[gv]} title={GameViewTitles[gv]} onClick={activityClick} />)}
      </ActivityList>
      <Blank />
      <MusicController />
      <Blank />
    </SideMenu>
    <ViewSwitcher currentView={gameView} />
    <Dialog visible={dialogState.visible} message={dialogState.message} buttons={dialogState.buttons} />
  </EngineContext.Provider>
}

export default App;
