import { Editor } from './Editor';
import { Exploration } from './Exploration';
import { Research } from './Research';
import { Statistics } from './Statistics';
import { GameViewHeader } from "../GameViewHeader";
import { GameView } from '../../types/GameView';
import { GameViewIcons, GameViewTitles } from "../../data/GameViewData";

function UnknownView() {
    return <h2>Unknown Game Component</h2>
}

const gameViewElements = {
    [GameView.Assembly]: <UnknownView />,
    [GameView.Blank]: <UnknownView />,
    [GameView.Exploration]: <Exploration />,
    [GameView.Extraction]: <UnknownView />,
    [GameView.Smelting]: <UnknownView />,
    [GameView.Research]: <Research />,
    [GameView.Statistics]: <Statistics />,
    [GameView.Editor]: <Editor />
}

export type ViewSwitcherProps = {
    currentView: GameView
}

export function ViewSwitcher(props: ViewSwitcherProps) {    
    return <div>
        <GameViewHeader title={GameViewTitles[props.currentView] || "Unknown Game Component"} icon={GameViewIcons[props.currentView]} /> 
        {gameViewElements[props.currentView]}
    </div>
}
