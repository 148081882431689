import { Button } from "./Button"
import { Window } from "./Window";
import { Sound } from "../services/AudioService";
import "./Dialog.css";
import { useViewportDimensions } from "../services/ViewportService";

export interface DialogButton {
    title: string,
    sound?: Sound,
    default?: boolean,
    cancel?: boolean,
    onClick?: () => void
}

export interface DialogProps {
    message: string,
    buttons: DialogButton[]
}

export interface DialogState extends DialogProps {
    visible: boolean
}

export function Dialog(props: DialogState) {
    const windowDimensions = useViewportDimensions();

    let dialogWidth = 720;
    let dialogHeight = 280;
    let createButton = (db: DialogButton, index: number) => {
        return <Button key={`Button${index}`} x={dialogWidth - 20 - ((index + 1) * 140)} y={dialogHeight - 70} title={db.title} sound={db.sound} onClick={props.buttons[index].onClick}/>
    };

    if (windowDimensions.width < windowDimensions.height)
    {
        dialogWidth = 340;
        dialogHeight = 720;
        //createButton = (db: DialogButton, index: number) => {
        //    return <Button key={`Button${index}`} x={dialogWidth - 160} y={dialogHeight - 10 - ((index + 1) * 60)} title={db.title} sound={db.sound} onClick={props.buttons[index].onClick}/>
        //};
    }

    return <svg className={props.visible ? "Dialog-container" : "Dialog-hidden"}>
        <rect x="0" y="0" width="100%" height="100%" className="Dialog-container-background" />
        <Window x={(windowDimensions.width - (dialogWidth + 60)) / 2} y={(windowDimensions.height - (dialogHeight + 60)) / 2} width={dialogWidth} height={dialogHeight}>
            <svg width={dialogWidth} height={dialogHeight} className="Dialog">
                <g>
                    <rect x="0" y="0" width={dialogWidth} height={dialogHeight} className="Dialog-background" />
                    <text x={dialogWidth / 2} y="90" width={dialogWidth - 200} fill="#dbe2e3" stroke="none" textAnchor="middle" fontFamily="Saira Extra Condensed" fontSize="30px" fontWeight="300">{props.message}</text>
                    {(props.buttons || []).map(createButton)}
                </g>
            </svg>
        </Window>
    </svg>;
}
