import React, { useState, useEffect, useRef } from "react";
import { initialiseMusic } from "../services/AudioService";
import songLifelike from "../assets/music/lifelike.mp3";
import "./MusicController.css";

enum MusicState {
    Playing,
    Stopped
}

// TODO: Don't hard code this...
const playlist: string[] = [
    songLifelike
];

const EDGE_BUTTON_PATH = "M23,33 l-13,-13 l13,-13 h43 v26 z";
const TRACK_SKIP_PATH = "M30,20 v-10 h2 v20 h-2 z M32,20 l10,-10 v20 z M42,20 l10,-10 v20 z";

export function MusicController(_props: any) {
    const [playlistIndex, setPlaylistIndex] = useState(0);
    const [state, setState] = useState(MusicState.Stopped);
    const audioElement = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        if (audioElement.current) {
            audioElement.current.src = playlist[playlistIndex];
            audioElement.current.currentTime = 0;
            if (state === MusicState.Playing) {
                audioElement.current.play();
            }
        }
    }, [playlistIndex, state]);

    useEffect(() => {
        initialiseMusic(audioElement.current!);
    }, []);

    const onBackClick = () => {
        if (playlistIndex === 0)
            setPlaylistIndex(playlist.length - 1);
        else
            setPlaylistIndex(playlistIndex - 1);
    }

    const onForwardClick = () => {
        setPlaylistIndex((playlistIndex + 1) % playlist.length);
    }

    const onPlayClick = () => {
        if (state === MusicState.Stopped) {
            audioElement.current?.play();
            setState(MusicState.Playing);
        }
    };

    const onStopClick = () => {
        if (state === MusicState.Playing) {
            audioElement.current?.pause();
            setState(MusicState.Stopped);
        }
    }

    const onEnded = () => {
        if (playlist.length > 1) {
            setPlaylistIndex((playlistIndex + 1) % playlist.length);
        }
        else {
            audioElement.current!.currentTime = 0;
        }
    };

    return <React.Fragment>
        <audio ref={audioElement} onEnded={onEnded}/>
        <svg width={260} height={50} className="MusicController">
            <g transform="translate(15,5)">
                <path d="M20,40 l-20,-20 l20,-20 h180 l20,20 l-20,20 Z" className="MusicController-border" />
                <g className="MusicController-button" onClick={onBackClick}>
                    <path d={EDGE_BUTTON_PATH} />
                    <path d={TRACK_SKIP_PATH} className="MusicController-button-icon" />
                </g>
                {state === MusicState.Stopped ?
                    <g className="MusicController-button" onClick={onPlayClick}>
                        <path d="M66,7 h90 v26 h-90 z" />
                        <path d="M116,20 l-10,-10 v20 z" className="MusicController-button-icon" />
                    </g>
                    :
                    <g className="MusicController-button" onClick={onStopClick}>
                        <path d="M66,7 h90 v26 h-90 z" />
                        <path d="M100,30 v-20 h20 v20 z" className="MusicController-button-icon" />
                    </g>
                }
                <g className="MusicController-button" onClick={onForwardClick}>
                    <g transform="translate(220,40) rotate(180)">
                        <path d={EDGE_BUTTON_PATH} />
                        <path d={TRACK_SKIP_PATH} className="MusicController-button-icon" />
                    </g>
                </g>
            </g>
        </svg>
    </React.Fragment>
}
