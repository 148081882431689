import missingIcon from '../assets/icons/missing.svg';
import './GameViewHeader.css';

export interface GameViewHeaderProps {
    title: string,
    icon?: string,
}

export function GameViewHeader(props: GameViewHeaderProps) {
    return <div className="GameViewHeader">
        <img src={props.icon || missingIcon} alt="" />
        <h1>{props.title}</h1>
    </div>
}
