import { useContext } from "react";
import { EngineContext } from "../../contexts/EngineContext";
import "./Statistics.css";

export interface StatisticsProps {
}

export function Statistics(_props: StatisticsProps) {
    const [engine, gameState] = useContext(EngineContext);

    if (!engine || !gameState) {
        return <div className="Statistics">
            Game is not running or the state is invalid
        </div>
    }

    const ticks = gameState.statistics.total_ticks;
    const totalSeconds = Math.floor(ticks / 10);
    
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor(totalSeconds / 3600) % 24;
    const minutes = Math.floor(totalSeconds / 60) % 60;
    const seconds = totalSeconds % 60;

    return <div className="Statistics">
        <table>
            <tbody>
                <tr>
                    <th>Total game ticks</th>
                    <td>{gameState.statistics.total_ticks}</td>
                </tr>
                <tr>
                    <th>Total game time</th>
                    <td>{days.toFixed(0)} day(s), {hours.toFixed(0).padStart(2, "0")}:{minutes.toFixed(0).padStart(2, "0")}:{(seconds % 60).toFixed(0).padStart(2, "0")}</td>
                </tr>
            </tbody>
        </table>
    </div>
}
