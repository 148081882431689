import { useContext } from 'react';
import { EngineContext } from '../../contexts/EngineContext';
import { Button } from '../Button';
import { DialogService } from '../../services/DialogService';
import { Sound } from '../../services/AudioService';

export interface ExplorationProps {
}

export function Exploration(_props: ExplorationProps) {
    const engine = useContext(EngineContext);

    const buttonClick = () => {
        DialogService.display({
            message: "These maps are not yet implemented",
            buttons: [
                { title: "Button1", sound: Sound.Reject },
                { title: "Button2", sound: Sound.Reject }
            ]
        }, Sound.Attention);
    }

    return <div>
        <Button title="World 1" onClick={buttonClick} />
        <Button title="World 2" onClick={buttonClick} />
        <Button title="World 3" onClick={buttonClick} />
        <Button title="World 4" onClick={buttonClick} />
        <Button title="World 5" onClick={buttonClick} />
    </div>
}
