import React, { PointerEvent } from "react";
import lockedIcon from "../assets/icons/locked.svg";
import missingIcon from "../assets/icons/missing.svg";
import { playSound, Sound } from "../services/AudioService";
import "./ActivityList.css";


export interface ActivityListProps {
    children?: React.ReactNode;
}

export type ListItemCallback<T> = (value: T) => void;

export interface ListItemProps<T> {
    title: string,
    value: T,
    icon?: string,
    locked?: boolean,
    onClick?: ListItemCallback<T>
}

export function ActivityList(props: ActivityListProps) {
    return <div className="ActivityList">
        <svg height={25} className="ActivityList-top">
            <path d="M-10,25 v-20 h250 l20,20" />
        </svg>
        <div className="ActivityList-content">
            {props.children}
        </div>
        <svg height={25} className="ActivityList-bottom">
            <path d="M-10,0 v20 h250 l20,-20" />
        </svg>
    </div>
}

export function ListItem<T>(props: ListItemProps<T>) {
    const blip = (e: PointerEvent) => {
        if (e.pointerType === "mouse" && !props.locked) {
            playSound(Sound.Blip);
        }
    }

    const listItemOnClick = () => {
        if (props.onClick)
            props.onClick(props.value)
    }

    return <div className={`ActivityList-item${props.locked ? "" : " ActivityList-item-unlocked"}`} onPointerEnter={blip} onClick={listItemOnClick}>
        <img src={props.icon || missingIcon} alt="" className="ActivityList-item-icon" />
        <div className="ActivityList-item-text">{props.title}</div>
        {props.locked ? <img src={lockedIcon} alt=""/> : <React.Fragment />}
    </div>
}
