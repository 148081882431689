import { GameView } from '../types/GameView';

import compassIcon from '../assets/icons/compass.svg';
import extractionIcon from '../assets/icons/extraction.svg';
import smeltingIcon from '../assets/icons/smelting.svg';
import conicalFlaskIcon from '../assets/icons/conicalflask.svg';
import statisticsIcon from '../assets/icons/statistics.svg';

export const GameViewIcons = {
    [GameView.Blank]: undefined,
    [GameView.Assembly]: undefined,
    [GameView.Exploration]: compassIcon,
    [GameView.Extraction]: extractionIcon,
    [GameView.Smelting]: smeltingIcon,
    [GameView.Research]: conicalFlaskIcon,
    [GameView.Statistics]: statisticsIcon,
    [GameView.Editor]: undefined,
}

export const GameViewTitles = {
    [GameView.Blank]: "Rudis Materiae",
    [GameView.Assembly]: "Assembly",
    [GameView.Exploration]: "Exploration",
    [GameView.Extraction]: "Extraction",
    [GameView.Smelting]: "Smelting",
    [GameView.Research]: "Research",
    [GameView.Statistics]: "Statistics",
    [GameView.Editor]: "Editor",
}
