import { MapViewer } from '../../MapViewer';
import { ChangeEvent, useState, useReducer, Reducer } from "react";
import './style/Editor.css';
import { MapData } from '../../MapViewer/types/MapData';
import { CellType } from '../../MapViewer/types/CellType';

export interface EditorProps {

};

function generateMapData(width: number, height: number, _seed: number): MapData {
    return {
        width,
        height,
        cellTypes: Array<CellType>(width * height).fill(CellType.None)
    };
}

interface Point {
    x: number;
    y: number;
}

type ToggleCellTypeAction = Readonly<{
    operation: 'toggle';
    point: Point;
}>;

type ChangeMapWidthAction = Readonly<{
    operation: 'changeWidth';
    width: number;
}>;

type ChangeMapHeightAction = Readonly<{
    operation: 'changeHeight';
    height: number;
}>;

export type MapAction = ToggleCellTypeAction | ChangeMapWidthAction | ChangeMapHeightAction;

function toggleCellType(state: MapData, p: Point): MapData {
    const cellAddress = p.y * state.width + p.x;
    const allCellTypes = state.cellTypes;
    switch (allCellTypes[cellAddress]) {
        case CellType.None:
            allCellTypes[cellAddress] = CellType.Water;
            break;
        
        case CellType.Water:
            allCellTypes[cellAddress] = CellType.Beach;
            break;
        
        case CellType.Beach:
            allCellTypes[cellAddress] = CellType.Grass;
            break;
        
        case CellType.Grass:
            allCellTypes[cellAddress] = CellType.CopperNode;
            break;
        
        default:
            allCellTypes[cellAddress] = CellType.None;
    }

    return {
        ...state, cellTypes: allCellTypes
    };
}

function changeMapWidth(state: MapData, newWidth: number): MapData {
    const newCellTypes = Array<CellType>(state.height * newWidth).fill(CellType.None);
    for (let y = 0; y < state.height; y++) {
        for (let x = 0; x < state.width; x++) {
            const cellAddr = y * state.width + x;
            const newCellAddr = y * newWidth + x;
            newCellTypes[newCellAddr] = state.cellTypes[cellAddr];
        }
    }
    return { ...state, width: newWidth, cellTypes: newCellTypes };
}

function changeMapHeight(state: MapData, newHeight: number): MapData {
    const newCellTypes = Array<CellType>(newHeight * state.width).fill(CellType.None);
    for (let y = 0; y < state.height; y++) {
        for (let x = 0; x < state.width; x++) {
            const cellAddr = y * state.width + x;
            newCellTypes[cellAddr] = state.cellTypes[cellAddr];
        }
    }
    return { ...state, height: newHeight, cellTypes: newCellTypes };
}

function reduceMapAction(state: MapData, action: MapAction): MapData {
    switch (action.operation) {
        case 'toggle':
            return toggleCellType(state, action.point);
        
        case 'changeWidth':
            return changeMapWidth(state, action.width);
        
        case 'changeHeight':
            return changeMapHeight(state, action.height);
    }
}

export function Editor(_props: EditorProps) {
    const defaultWidth = 10;
    const defaultHeight = 10;

    const [zoom, setZoom] = useState(1);
    const [seed, setSeed] = useState(6432);
    const [mapData, dispatchMapData] = useReducer<Reducer<MapData,MapAction>>(reduceMapAction, generateMapData(defaultWidth, defaultHeight, seed));

    const onWidthChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatchMapData({operation: 'changeWidth', width: e.target.valueAsNumber});
    }

    const onHeightChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatchMapData({operation: 'changeHeight', height: e.target.valueAsNumber});
    }

    const onCellClick = (x: number, y: number) => {
        dispatchMapData({operation: 'toggle', point: {x, y}});
    };

    return <div className="Editor">
        <div className="Editor-controlPanel">
            <div className="Editor-control">
                <label htmlFor="width">Width ({mapData.width})</label>
                <input type="range" min={10} max={100} name="width" defaultValue={defaultWidth} onChange={onWidthChange} />
            </div>
            <div className="Editor-control">
                <label htmlFor="height">Height ({mapData.height})</label>
                <input type="range" min={10} max={100} name="height" defaultValue={defaultHeight} onChange={onHeightChange} />
            </div>
            <div className="Editor-control">
                <label htmlFor="seed">Seed</label>
                <input type="number" min={0} max={65535} name="seed" defaultValue={seed} />
            </div>
        </div>
        <MapViewer zoom={zoom} mapData={mapData} onCellClick={onCellClick} />
    </div>
}

