import { useEffect, useRef, useState, UIEvent, useReducer } from 'react';
import { CellType } from './types/CellType';
import { MapData } from './types/MapData';
import './style/MapViewer.css';

const CELL_WIDTH = 80;
const CELL_HEIGHT = Math.sqrt(3) * (CELL_WIDTH / 2);

export interface Bounds {
    left: number;
    top: number;
    width: number;
    height: number;
}

export type CellClickEventHandler = (x: number, y: number) => void;

export interface MapViewerProps {
    mapData: MapData;
    zoom: number;
    onCellClick: CellClickEventHandler;
}

const CELL_STYLES = {
    [CellType.None]: "MapViewer-node-none",
    [CellType.Beach]: "MapViewer-node-beach",
    [CellType.Grass]: "MapViewer-node-grass",
    [CellType.Water]: "MapViewer-node-water",
    [CellType.CopperNode]: "MapViewer-node-copperNode",
}

function createCell(x: number, y: number, cell: CellType, bounds: Bounds, onCellClick?: CellClickEventHandler) {
    const top = 1.00 * CELL_HEIGHT * y + (((x + 1) % 2) * CELL_HEIGHT / 2);
    const left = 0.75 * CELL_WIDTH * x;
    if (top + (1.50 * CELL_HEIGHT) < bounds.top || top > bounds.top + bounds.height)
        return;
    if (left + CELL_WIDTH < bounds.left || left > bounds.left + bounds.width)
        return;
    return <svg className="MapViewer-node" key={`${x}x${y}`} width={CELL_WIDTH} height={CELL_HEIGHT} style={{top, left}}>
        <g transform={`scale(${CELL_WIDTH},${CELL_HEIGHT})`}>
            <path className={CELL_STYLES[cell]} d="M0,0.5 L0.25,0.00 L0.75,0.00 L1.00,0.50 L0.75,1.00 L0.25,1.00 Z" onClick={() => onCellClick && onCellClick(x, y)} />
        </g>
    </svg>
}

export function MapViewer(props: MapViewerProps) {
    const mapViewRef = useRef<HTMLDivElement>(null);
    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (mapViewRef.current !== null) {
            setLeft(mapViewRef.current.scrollLeft);
            setTop(mapViewRef.current.scrollTop);
            setWidth(mapViewRef.current.scrollWidth);
            setHeight(mapViewRef.current.scrollHeight);
        }
    }, []);

    const onScroll = (e: UIEvent<HTMLDivElement>) => {
        setLeft(e.currentTarget.scrollLeft);
        setTop(e.currentTarget.scrollTop);
    };

    const onResize = (e: UIEvent<HTMLDivElement>) => {
        setWidth(e.currentTarget.scrollWidth);
        setHeight(e.currentTarget.scrollWidth);
    };

    const requiredWidth = 0.75 * CELL_WIDTH * props.mapData.width;
    const requiredHeight = CELL_HEIGHT * props.mapData.height;

    return <div ref={mapViewRef} className="MapViewer" onScroll={onScroll} onResize={onResize}>
        <div className="MapViewer-scrollable-inner" style={{width: requiredWidth, height: requiredHeight}}>
            {props.mapData.cellTypes.map((cellType, index) => createCell(index % props.mapData.width, Math.floor(index / props.mapData.width), cellType, { left, top, width, height }, props.onCellClick))}
        </div>
    </div>
}
