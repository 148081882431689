export enum GameView {
    Blank,
    Exploration,
    Extraction,
    Smelting,
    Assembly,
    Research,
    Statistics,
    Editor,
    // and so on...
};

