import "./Blank.css";

export enum BlankType {
    Fixed,
    Stretchy
}

export interface BlankProps {
    type?: BlankType
}

export function Blank(props: BlankProps) {
    return <div className={`Blank ${(props.type === undefined || props.type === BlankType.Fixed) ? "Blank-fixed" : "Blank-stretchy"}`} />
}
